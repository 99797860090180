<template>
  <d-view-card
    ref="view"
    :fetch-function="prepaymentController.getPrepayments"
    :pagination="pagination"
    :filters="filters"
    :filter-transformer="clearFilters"
    readonly-data
  >
    <template #default="{ loading, find, data, filtersEntity }">
      <div class="col-shrink row q-px-md q-pt-md">
        <q-card class="full-width">
          <q-card-section class="row col q-col-gutter-sm">
            <d-user-select
              v-if="!isAgent"
              v-model="filtersEntity.agentId"
              label="Agent"
              :multiple="false"
              use-input
              emit-value
              map-options
              filled
              dense
              :options="agents"
              hide-bottom-space
              hide-hint
            />

            <div :style="{ minWidth: '160px' }">
              <q-select
                v-model="filters.status"
                :options="statusOptions"
                emit-value
                map-options
                dense
                filled
                clearable
                label="Status"
              />
            </div>

            <div :style="{ minWidth: '160px' }">
              <q-select
                v-model="filters.type"
                :options="typeOptions"
                emit-value
                map-options
                dense
                filled
                clearable
                label="Type"
              />
            </div>

            <d-date-range
              ref="dateRange"
              :value="[
                filtersEntity.createdAt.from,
                filtersEntity.createdAt.to,
              ]"
              @input="setDateRange"
            />
          </q-card-section>
          <q-card-actions align="right">
            <q-btn
              :disable="loading"
              outline
              color="primary"
              label="Report"
              @click="onReportHandler"
            />
            <q-btn
              :disable="loading"
              unelevated
              color="primary"
              label="Apply"
              class="q-px-sm"
              @click="find"
            />
          </q-card-actions>
        </q-card>
      </div>
      <div class="col-grow">
        <div class="q-pa-md">
          <d-payment-history :grid="$q.screen.lt.md" :items="data" />
        </div>
      </div>
    </template>
  </d-view-card>
</template>

<script>
import { DDateRange } from '@/features/date-range-picker';
import { DPaymentHistory } from '@/features/payment-history-table';
import DUserSelect from '@/features/user-select';
import DViewCard from '@/layouts/view-card';
import { prepaymentController, usersController } from '@/shared/api';
import { ROLES } from '@/shared/constants';
import withReportLoader from '@/shared/mixins/withReportLoader';
import { authUtils, notify, objectUtils } from '@/shared/utils';

export default {
  components: { DViewCard, DDateRange, DPaymentHistory, DUserSelect },
  mixins: [withReportLoader],
  data: () => ({
    agents: [],
    prepaymentController: prepaymentController(authUtils.getRoleByHierarchy()),
    filters: {
      agentId: null,
      status: null,
      type: null,
      createdAt: {
        from: '',
        to: '',
      },
    },
    pagination: {
      lastId: 0,
      maxResults: 1000,
      descending: true,
    },
  }),
  computed: {
    isAgent: () => authUtils.getRoleByHierarchy() === ROLES.ROLE_AGENT,
    statusOptions() {
      return [
        {
          label: 'New',
          value: 0,
        },
        {
          label: 'Pending',
          value: 1,
        },
        {
          label: 'Success',
          value: 2,
        },
        {
          label: 'Declined',
          value: 3,
        },
      ];
    },
    typeOptions() {
      return [
        {
          label: 'Auto',
          value: 0,
        },
        {
          label: 'Manual',
          value: 1,
        },
      ];
    },
  },
  mounted() {
    if (!this.isAgent) {
      this.loadAgents();
    }
  },
  methods: {
    async loadAgents() {
      try {
        const { data } = await usersController(
          authUtils.getRoleByHierarchy()
        ).agentsForBalance();
        this.agents = Object.freeze(data);
      } catch (e) {
        notify.error();
      }
    },
    setDateRange(value) {
      this.filters.createdAt.from = value[0];
      this.filters.createdAt.to = value[1];
    },
    async onPaginationHandler({ rowsPerPage }) {
      this.pagination.maxResults = rowsPerPage;
      await this.$refs.view.find();
    },
    clearFilters: objectUtils.removeEmptyValuesRecursively,

    onReportHandler() {
      this.callPromptForEmail().onOk(async (email) => {
        try {
          await this.prepaymentController.applyReport({
            filter: this.clearFilters(this.filters),
            email,
          });
          notify.success('Report request sent successfully');
        } catch (e) {
          notify.error('Something went wrong while sending a report request.');
        }
      });
    },
  },
};
</script>
